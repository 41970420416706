var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'carian.semak'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("back")))])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center text-uppercase"
  }, [_vm._v(_vm._s(_vm.$t("testator-info")))]), _c('form', {
    staticClass: "login-form"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("fullname")) + ":")]), _c('p', [_vm._v(_vm._s(this.fullname))])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("email")) + ":")]), _c('p', [_vm._v(_vm._s(this.email))])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mykad")) + ":")]), _c('p', [_vm._v(_vm._s(this.ic_number))])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("old-ic")) + ":")]), _c('p', [_vm._v(_vm._s(this.old_ic_number ? this.old_ic_number : "-"))])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("mobile-no")) + ":")]), _c('p', [_vm._v(_vm._s(this.mobile_number))])])]), _c('div', {
    staticClass: "col-md-6"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("homeoffice-no")) + ":")]), _c('p', [_vm._v(" " + _vm._s(this.home_phone_number ? this.home_phone_number : "-") + " ")])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "form-group"
  }, [_c('label', [_vm._v(_vm._s(_vm.$t("address")) + ":")]), _c('p', {
    staticStyle: {
      "text-transform": "uppercase"
    }
  }, [_vm._v(" " + _vm._s(this.address) + ","), _c('br'), _vm._v(" " + _vm._s(this.postcode) + ","), _c('br'), _vm._v(_vm._s(this.city) + ","), _c('br'), _vm._v(_vm._s(this.state) + " ")])])])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }